/** @jsx jsx */
import * as React from 'react';
import AboutUs from '../../components/About/AboutUs';
import Staff from '../../components/About/Staff';
import History from '../../components/About/History';
import Layout from '../../components/Layout/index';
import Header from '../../components/Common/Header';
import { Seo } from '../../components/Seo';
import { jsx } from 'theme-ui';

const AboutPage = () => {
  const data = [
    {
      title: 'Our Families',
      description:
        'Though the vast majority of our families have historically lived in North America, the NEDC serves and is open to families from all over the world. ',
      image: '/images/chart-current-patients-2.png',
    },
    {
      title: 'Multiracial Embryos',
      description:
        'The NEDC has a significant number of multi-racial embryos available. No matter your background, the NEDC can help you complete your family. ',
      image: '/images/chart-multiracial-embryos.png',
    },
    {
      title: 'Our Embryos',
      description: 'Embryos of all ethnicities have been donated to the NEDC. ',
      image: '/images/chart-our-embryos.png',
    },
  ];
  return (
    <Layout>
      <Seo
        title="About the NEDC | National Embryo Donation Center"
        description="Since 2003, the National Embryo Donation Center (NEDC) has been the leading comprehensive non-profit embryo donation program in the US. Learn more about us"
      />
      <div>
        <Header
          title="About the NEDC"
          description={'The Leading Comprehensive Non-Profit Embryo Donation Program'}
          video={'https://www.youtube.com/watch?v=hXOMzImsFfk'}
          background={'/images/header/jessica-rockowitz-5NLCaz2wJXE-unsplash.png'}
        />
        <AboutUs />
        <section>
          <div className="content">
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '2rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: '3rem',
                  }}
                >
                  <div
                    sx={{
                      bg: '#f5f5f4',
                      flex: ['1 0 100%', null, null, '1 0 calc(33% - 1rem)'],
                      maxWidth: ['100%', null, null, 'calc(33% - 1rem)'],
                      p: '2rem',
                      order: index === 1 ? [1, null, null, 0] : 1,
                    }}
                  >
                    <h3
                      sx={{
                        variant: 'text.xl',
                        mb: '1rem',
                      }}
                    >
                      {item.title}
                    </h3>
                    <p
                      sx={{
                        variant: 'text.normal',
                      }}
                    >
                      {item.description}
                    </p>
                  </div>
                  <div
                    sx={{
                      flex: '1 0 calc(66% - 1rem)',
                      maxWidth: '600px',
                    }}
                  >
                    <img src={item.image} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <Staff />
        <History />
      </div>
    </Layout>
  );
};

export default AboutPage;
